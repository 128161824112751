import React from "react";
import { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";
const GlobalStyles = createGlobalStyle`
    * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    }

    html {
    font-size: 62.5%;
    }
    body {
    font-family: "Montserrat", sans-serif;
    overflow-x: hidden;
    margin: 0;
    }

    h1 {
    font-size: 2.5rem;
    }
    li,
    button,
    label,
    input,
    a,
    p {
    font-size: 2rem;
    }
    h2 {
    font-size: 4rem;
    }
    h3 {
    font-size: 2.5rem;
    }
    h4,
    h5 {
    font-size: 2rem;
    }

    ul {
    list-style: none;
    }
    a {
    text-decoration: none;
    color: #2c2c2c;
    }


`;

function Layout({ children }) {
  return (
    <>
      <Helmet
        encodeSpecialCharacters={true}
        defaultTitle="Sophanorin-Portfolio"
        titleTemplate="%s | Sophanorin"
      >
        <meta
          name="description"
          content="A junior software developer with hands-on experience in testing, including performance, functional, integration, system, and user acceptance. *free web projects in portfolio"
        />
      </Helmet>
      <GlobalStyles />
      {children}
    </>
  );
}

export default Layout;
