import React from "react";
import "./Footer.module.scss";
import TelegramIcon from "../assets/icons/telegram_24x24.svg";
import FacebookIcon from "../assets/icons/facebook_24x24.svg";
import GithubIcon from "../assets/icons/github_24x24.svg";

function Footer() {
  return (
    <footer>
      <h4>
        &copy; {new Date().getFullYear()} Sophanorin HOEU. All rights reserved.
        Built with <a href="www.gatsbyjs.com/">Gatsby</a>{" "}
      </h4>
      <ul>
        <li>
          <a href="https://t.me/HoeuSophanorin">
            <img src={TelegramIcon} alt="twitter-social" />
          </a>
        </li>
        <li>
          <a href="www.facebook.com/Heou.sophanorin/">
            <img src={FacebookIcon} alt="instagram-social" />
          </a>
        </li>
        <li>
          <a href="https://github.com/Sophanorin" target="blank">
            <img src={GithubIcon} alt="youtube-social" />
          </a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
