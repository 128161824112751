import { Link, graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import * as navigationStyles from "./Navigation.module.scss";
import logo from "../assets/icons/code.svg";
import MailIcon from "../assets/icons/mail.svg";

function Navigation() {
  const {
    site: {
      siteMetadata: { title, email, phone_number },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          email
          phone_number
        }
      }
    }
  `);

  const [navbar, setNavbar] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [showToggleBtn, setShowToggleBtn] = useState(false);
  const updateStateHandler = () => {
    if (window.scrollY >= 150) {
      setNavbar(true);
      setShowToggleBtn(true);
      setShowNavbar(true);
    } else {
      setNavbar(false);
      setShowToggleBtn(false);
      setShowNavbar(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", updateStateHandler);
  }, []);

  return (
    <header className={navigationStyles.main_head}>
      <div
        className={`${navbar && navigationStyles.nav_background} ${
          showNavbar && navigationStyles.hide_nav
        }`}
      >
        <nav>
          <Link className={navigationStyles.logo} to="/">
            <img src={logo} alt="logo" />
            <h1>{title}</h1>
          </Link>
          <ul>
            <li>
              <p>{phone_number}</p>
            </li>
            <li>
              <img src={MailIcon} alt="mail"></img>
              <p>{email}</p>
            </li>
          </ul>
          <ul>
            <li>
              <Link activeClassName={navigationStyles.active} to="/">
                Home
              </Link>
            </li>
            <li>
              <Link activeClassName={navigationStyles.active} to="/portfolio">
                Portfolio
              </Link>
            </li>
          </ul>
        </nav>
        <div className={navigationStyles.nav_bottom}>
          <Link
            to="https://drive.google.com/uc?id=1HQaXRwtP95eSPsCLKtSRPeh95KiYJk3g&export=download"
            download
          >
            Resume
          </Link>
          <Link className={navigationStyles} to="/#contact">
            Let's talk
          </Link>
          {showToggleBtn && (
            <button onClick={() => setShowNavbar(!showNavbar)}>
              {showNavbar ? "Show" : "Hide"}
            </button>
          )}
        </div>
      </div>
    </header>
  );
}

export default Navigation;
